<template>
  <div>
    <v-row class="pa-4 ma-0 mb-4 d-flex flex-column">
      <div class="d-flex">
        <div class="font-weight-bold primaryColor">Positions</div> <v-progress-circular class="ml-2" indeterminate
          v-if="positionCountLoader" size="18" :width="2" color="black">
        </v-progress-circular>
      </div>
      <v-card class="mt-2">
        <v-simple-table id="holding_count_kam_table" v-if="positionCountObj">
          <thead>
            <tr>
              <th class="fsize12 font-weight-normal tableContent text-center">Exchange</th>
              <th class="fsize12 font-weight-normal tableContent text-center">Count</th>
              <th class="fsize12 font-weight-normal tableContent text-center">Created On</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, idex) in positionCountObj" :key="idex">
              <td class="text-center tableContent">{{ i.exchange }}</td>
              <td class="text-center tableContent">{{ i.count }}</td>
              <td class="text-center tableContent">{{ i.createdOn ? i.createdOn : '-' }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-row>
    <v-row class="pa-4 ma-0 d-flex flex-column">
      <div class="d-flex">
        <div class="font-weight-bold primaryColor">Holdings</div> <v-progress-circular class="ml-2" indeterminate
          v-if="hodingsCountLoader" size="18" :width="2" color="black">
        </v-progress-circular>
      </div>
      <v-card class="mt-2">
        <v-simple-table id="holding_count_kam_table" v-if="holdingsCountList">
          <thead>
            <tr>
              <th class="fsize12 font-weight-normal tableContent text-center">Exchange</th>
              <th class="fsize12 font-weight-normal tableContent text-center">Count</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, idex) in holdingsCountList" :key="idex">
              <td class="text-center tableContent">{{ i.exchange }}</td>
              <td class="text-center tableContent">{{ i.count }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapState("reports", ['positionCountObj', 'holdingsCountList', 'positionCountLoader', 'hodingsCountLoader']),
  },
  created() {
    this.$store.dispatch('reports/getKamPositionsCount')
    this.$store.dispatch('reports/getKamHoldingsCount')
  }
}
</script>

<style></style>